import criptoJS from "crypto-js";
import { useEffect } from "react";
import history from "../../helpers/history";
import { useAuth } from "../../hooks/auth";
import { getTokenViaHashId } from "../../services/auth";
import jwtDecode from "jwt-decode";

const TrocarUsuario: React.FC = () => {
  const { signInChange } = useAuth();

  const queryParameters = new URLSearchParams(window.location.search);
  const hashId = queryParameters.get("hashId");

  const tokenEnv = process.env.REACT_APP_API_KEY || "";

  const decodedCriptoValue = atob(hashId ?? "");
  const decryptedBytes = criptoJS.AES.decrypt(decodedCriptoValue, tokenEnv);
  const decriptoHashId = decryptedBytes.toString(criptoJS.enc.Utf8);

  const changeUsuario = async () => {
    const jwtToken = await getTokenViaHashId(decriptoHashId) ?? "";
    const decodedJwt = jwtDecode(jwtToken ?? "") as Record<string, any>;
    await signInChange({
      idUsuario:
        "IdUsuario" in decodedJwt
          ? parseInt(decodedJwt.IdUsuario as string)
          : 0,
    });
    history.push(`/dashboard`);
  };

  useEffect(() => {
    changeUsuario();
  }, []);

  return null;
};

export default TrocarUsuario;
