import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { ToastContext } from "../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../contexts/sweetAlert/alertContext";
import getValidationErrors from "../../../helpers/getValidateErrors";
import DataTable from "../../../components/DataTable";
import DataNotFound from "../../../components/DataNotFound";
import api from "../../../services/api";
import { File, Select } from "../../../components/Form";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import { getSingleErrorMessage } from "../../../helpers/getSingleErrorMessage";

import {
  excelToJson,
  excelToJsonMultiTabs,
} from "../../../helpers/ExcelToJson";

import {
  IListProdutos,
  IListLogMaterial,
} from "../../../common/interfaces/importar.interfaces";
import { cpuUsage } from "process";
import { exportToExcel } from "../../../helpers/ExportToExcel";
import { ToastErrorContext } from "../../../contexts/toast/toastErrorContext";
import { tipoMaterial } from "../../../components/Form/Select/options";
import { toast } from "react-toastify";
import { omit } from "lodash";

const TabProdutos: React.FC = () => {
  // Refs
  const formRef = useRef<FormHandles>(null);


  //Context
  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const toastError = useContext(ToastErrorContext);

  //Fields Typed
  const DataTableTS: any = DataTable;

  //States
  const [tableData, setTableData] = useState<IListProdutos[]>([]);
  const [logImportacao, setLogImportacao] = useState<IListLogMaterial[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [homologar, setHomologar] = useState(false);
  const [disponivel, setDisponivel] = useState(false);
  const [selectedTipoProduto, setSelectedTipoProduto] = useState("");
  const [referencia, setReferencia] = useState([]);
  const [show, setShow] = useState(false);
  const [tipoMaterial, setTipoMaterial] = useState([]);

  //Effects
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);
        const request = await api.get("produto/ListaImportacao");
        setTableData(request.data);

        const acaoPermitida = await api.get(
          "produto/acaoPermitidaImportacaoMaterial"
        );
        handleAcaoPermitida(acaoPermitida.data);

        const tipoMaterial = await api.get("produto/selectTipoMaterial");
        setTipoMaterial(tipoMaterial.data);
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    })();
  }, [reloadTable]);

  //Handle
  const handleAcaoPermitida = ({ homologar, disponibilizar }: any) => {
    setHomologar(homologar);
    setDisponivel(disponibilizar);
  };

  const handleSave = () => {
    formRef.current?.submitForm();
  };

  const handleClose = () => setShow(false);

  const handleDelete = async ({ id, tipoProduto }: IListProdutos) => {

    const title = `Excluir "${tipoProduto}"?`;
    const message = "Esse produto será excluído, isso não pode ser desfeito.";
    sweetAlertContext.danger(title, message, async () => {
      try {
        await api.delete(`produto/removerImportacao/${id}`);
        setTableData((prevState) =>
          prevState.filter(function (item) {
            return item.id !== id;
          })
        );
        sweetAlertContext.close();
      } catch (error) {
        const message = getSingleErrorMessage(error);
        if (message) {
          toastContext.notification(message, "warn");
        } else {
          toastContext.notification(
            "Ocorreu um erro ao realizar essa operação.",
            "error"
          );
        }
      }
    });
  };

  const handlePlanilhaPadrao = async () => {
    const dadosExportacao = await api.get(
      `${selectedTipoProduto}/exportarEstrutura`
    );

    const tabDados = [] as any;


    if (selectedTipoProduto === "VIDRO") {
      delete dadosExportacao.data.vidros[0].situacao;
      tabDados.push({ data: dadosExportacao.data.vidros, name: "VIDRO" });
      tabDados.push({ data: dadosExportacao.data.cores, name: "COR" });
    }else if (selectedTipoProduto === "KIT"){
      debugger
      delete dadosExportacao.data.kits[0].situacao;
      tabDados.push({ data: dadosExportacao.data.kits, name: "KIT" });
      tabDados.push({ data: dadosExportacao.data.medidas, name: "MEDIDA" });
      tabDados.push({ data: dadosExportacao.data.composicaos, name: "COMPOSICAO" });
    } 
    else {
      delete dadosExportacao.data[0].situacao;
      tabDados.push({ data: dadosExportacao.data, name: selectedTipoProduto });
    }

    exportToExcel(tabDados, selectedTipoProduto);
  };

  const handleChangeTipoProduto = async (selectedTipoMaterial: any) => {
    setSelectedTipoProduto(selectedTipoMaterial.label);

    formRef.current?.clearField("referencia");
    const referencia = await api.get(`geral/selecaoReferenciaMaterial/${selectedTipoMaterial.value}`);
    setReferencia(referencia.data);
  };

  const handleSubmit: SubmitHandler = async (produtos) => {
    try {
      formRef.current?.setErrors({});

      const SUPPORTED_FORMATS = [
        "'application/x-zip",
        "application/vnd.ms-excel",
        "application/msexcel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      const message = "Campo obrigatório";
      const schema = Yup.object().shape({
        referencia: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        tipoProduto: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(message),
        arquivo: Yup.mixed().test(
          "fileFormat",
          "O arquivo deve ser .xls",
          (value) => value && SUPPORTED_FORMATS.includes(value.type)
        ),
      });

      await schema.validate(produtos, { abortEarly: false });

      //Passou pela validação
      const { data: { tipoProduto } } = await api.get(`produto/ImportacaoAndamento`);

      if (tipoProduto) {
        sweetAlertContext.alert("Atenção", `Já existe um processo em andamento. Para prosseguir, conclua o processo em andamento ou elimine a planilha para iniciar um novo processo`);
      } else {
        const { data: { alerta } } = await api.get(`${produtos.tipoProduto.label}/acaoAlertaImportar`);

        if (alerta) {
          sweetAlertContext.warning("Atenção", `Há produtos em edição que poderão ser afetados pelos dados da planilha, deseja fazer a importação dos produtos mesmo assim?`, async () => {
            await handleImportarPlanilha(produtos);
          });
        }
        else {
          await handleImportarPlanilha(produtos);
        }
      }
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
      formRef.current?.setErrors(getValidationErrors(err));
    }
  };

  const handleImportarPlanilha = async (produtos: any) => {
    if (produtos.tipoProduto.label === "VIDRO") 
    {
      handleImportarPlanilhaVidro(produtos);
    } 
    else if (produtos.tipoProduto.label === "KIT") 
    {
      handleImportarPlanilhaKit(produtos);
    } 
    else 
    {
      excelToJson(produtos.arquivo, async (conteudo: any) => {
        try {
          setTableLoading(true);
          var arquivo = new FormData();
          arquivo.append("arquivo", produtos.arquivo);
          arquivo.append("conteudo", JSON.stringify(conteudo));
          arquivo.append("referencia", produtos.referencia.label);
          arquivo.append("tipoMaterial", produtos.tipoProduto.label);

          const { data } = await api.post(
            `${produtos.tipoProduto.label}/importarPlanilha`,
            arquivo
          );

          if (data.logs?.length == 0) {
            setReloadTable((reload) => !reload);
            toast.success("A ação foi concluída.");
          } else {
            setLogImportacao(data.logs);
            setShow(true);
          }
        } finally {
          setTableLoading(false);
        }
      });
    }
  };

  const handleImportarPlanilhaKit = async (produtos:any) => {
    excelToJsonMultiTabs(produtos.arquivo, [0, 1, 2], async (conteudo: any) => {
      
      setTableLoading(true);

      try {
        var arquivo = new FormData();
        arquivo.append("arquivo", produtos.arquivo);
        arquivo.append(
          "conteudo",
          JSON.stringify({ kits: conteudo[0], medidas: conteudo[1], composicaos: conteudo[2] })
        );
        arquivo.append("referencia", produtos.referencia.label);
        arquivo.append("tipoMaterial", produtos.tipoProduto.label);

        const { data } = await api.post(
          `${produtos.tipoProduto.label}/importarPlanilha`,
          arquivo
        );
        if (data.logs.length == 0) {
          setReloadTable((reload) => !reload);
          toast.success("A ação foi concluída.");
        } else {
          setLogImportacao(data.logs);
          setShow(true);
        }
      } catch (error: any) {

      } finally {
        setTableLoading(false);
      }

    })
  }

  const handleImportarPlanilhaVidro = async (produtos: any) => {
    excelToJsonMultiTabs(produtos.arquivo, [0, 1], async (conteudo: any) => {

      setTableLoading(true);

      try {
        var arquivo = new FormData();
        arquivo.append("arquivo", produtos.arquivo);
        arquivo.append(
          "conteudo",
          JSON.stringify({ vidros: conteudo[0], cores: conteudo[1] })
        );
        arquivo.append("referencia", produtos.referencia.label);
        arquivo.append("tipoMaterial", produtos.tipoProduto.label);

        const { data } = await api.post(
          `${produtos.tipoProduto.label}/importarPlanilha`,
          arquivo
        );
        if (data.logs.length == 0) {
          setReloadTable((reload) => !reload);
          toast.success("A ação foi concluída.");
        } else {
          setLogImportacao(data.logs);
          setShow(true);
        }
      } catch (error: any) {

      } finally {
        setTableLoading(false);
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Tipo",
        accessor: "tipoProduto",
        display: "align-middle",
      },
      {
        Header: "Referência",
        accessor: "referencia",
        display: "align-middle",
      },
      {
        Header: "Atualização",
        accessor: "dataCriacao",
        display: "align-middle",
      },
      {
        Header: "Situação",
        accessor: "situacao",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle column-table-2b",
        Cell: ({
          row: { original },
        }: {
          row: { original: any };
        }) => (
          <Button
            title="Excluir"
            variant="ghost-dark"
            className="p-2"
            onClick={(event) => {
              event.preventDefault();
              handleDelete(original);
            }}>
            <i className="far fa-trash-alt icon m-0"></i>
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="mb-3 col pr-0 text-right">
        <span className="d-none d-sm-inline">
          <Button
            className="mr-2"
            variant="white"
            disabled={selectedTipoProduto == ""}
            onClick={handlePlanilhaPadrao}
          >
            <i className="fas fa-file-download"></i> Planilha Padrão
          </Button>
          <Button onClick={handleSave} variant="white">
            Importar
          </Button>
        </span>
      </div>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="col form-group menu-select-bug">
            <label className="form-label">Tipo de produto</label>
            <Select
              name="tipoProduto"
              placeholder="Selecione um tipo..."
              options={tipoMaterial}
              onChange={(e) => handleChangeTipoProduto(e)}
            ></Select>
          </div>
          <div className="col form-group menu-select-bug">
            <label className="form-label">Referência</label>
            <Select
              name="referencia"
              placeholder="Selecione uma referência..."
              options={referencia}
            ></Select>
          </div>
        </div>
        <div className="form-row">
          <div className="col form-group">
            <label className="form-label">Arquivo</label>
            <File name="arquivo" placeholder="Procurar arquivo..." />
          </div>
        </div>
      </Form>
      <div className="mt-3">
        <div className={"dimmer" + (tableLoading ? " active" : "")}>
          <div className="loader"></div>
          <div
            className={
              "dimmer-content" + (tableLoading ? " medium-box-loader" : "")
            }
          >
            {tableData && (
              <DataTableTS
                columns={columns}
                data={tableData}
                reload={reloadTable}
                bordered={false}
                initialState={{
                  sortBy: [
                    {
                      id: "id",
                      desc: false,
                    },
                  ],
                }}
              />
            )}
            <DataNotFound
              visible={tableLoading == false ? tableData?.length === 0 : false}
            ></DataNotFound>
          </div>
        </div>
      </div>

      {/* Modal Log */}
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Erro - Log de Importação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-vcenter card-table">
            <thead>
              <tr>
                <td className="font-weight-bold">Linha</td>
                <td className="font-weight-bold">Código</td>
                <td className="font-weight-bold">Mensagem</td>
              </tr>
            </thead>
            {logImportacao.map((data: IListLogMaterial, index: number) => (
              <tr key={index}>
                <td className="text-muted">{data.numeroLinhaArquivo}</td>
                <td className="text-muted">{data.codigo}</td>
                <td className="text-muted">{data.mensagem}</td>
              </tr>
            ))}
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabProdutos;
