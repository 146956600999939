import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Input, Select } from "../../../../../components/Form";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import api from "../../../../../services/api";

import DataTable from "../../../../../components/DataTable";
import DataNotFound from "../../../../../components/DataNotFound";

import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { debounce } from "lodash";

const KitTabMedidasCores: React.FC<any> = ({kit}) => {
  const { pId, pString }: any = useParams();
  const formRef = useRef<FormHandles>(null);

  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const [reload, setReload] = useState(false);
  const [tableData, setTableData] = useState(([]));
  const [tableLoading, setTableLoading] = useState(false);
  const [coresData, setCoresData] = useState([]);
  const [selectedCores, setSelectedCores] = useState([]);
 
  useEffect(() => {
    (async () => {
      try {
        setTableLoading(true);
        if(kit?.id != undefined ){
        const endpointMedidasCores = `Kit/MedidasCores/${kit?.id}`;
        const { data: medidasCoresData } = await api.get(endpointMedidasCores);

        setTableData(medidasCoresData);
        }
        const endpointCores = `kit/cores/`;
        const { data: coresData } = await api.get(endpointCores);

        setCoresData(coresData); 
  
      } catch (error) {
        console.error(error);
      } finally {
        setTableLoading(false);
      }
    })();
  }, [kit,reload]);

  const handleSubmit: SubmitHandler<any> = async (item, { reset }) => {
    
    try {
      const kitEngenhariaFerragem = {
        largura: Number(item.largura) ?? 0,
        altura: Number(item.altura) ?? 0,
        espessura: Number(item.espessura),
        cor: selectedCores.map((cor: any) => cor.value),
        idKit: kit?.id,
      };

      const endpoint = "kit/SalvarEngenharia";
      await api.post(endpoint, kitEngenhariaFerragem);
  
      setReload((reload) => !reload);
      reset();
      toastContext.notification("Item adicionado com sucesso!", "success");
    } catch (error) {
      toastContext.notification("Erro ao salvar item.", "error");
    }
  };


  const handleCoresChange = (selectedOptions: any) => {
    setSelectedCores(selectedOptions || []);
  };


  const handleDelete = (item: any) => {
    sweetAlertContext.danger(
      "Excluir Medidas?",
      "Essa ação não pode ser desfeita.",
      async () => {
        try {
          const endpoint = `kit/RemoverEngenharia/${item.id}`;
          await api.delete(endpoint);
          setTableData((prevState: any) =>
            prevState.filter((i: any) => i.id !== item.id)
          );
          sweetAlertContext.close();
        } catch (error) {
          toastContext.notification("Erro ao excluir o item.", "error");
        }
      }
    );
  };
  const getCorNome = (id : number) => {
    const cor = coresData && coresData.map((cor: any) => cor.label).find(cor => cor ===id)
    return cor ? cor.label : id;
  };
  const columns = [
    { Header: "Largura", accessor: "largura" },
    { Header: "Altura", accessor: "altura" },
    { Header: "Espessura", accessor: "espessura" },
    { Header: "Cores", accessor: "cores"  },
    {
      Header: "Ações",
      accessor: "acao",
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <Button variant="ghost-dark" className="p-2" onClick={() => handleDelete(original)}>
          <i className="far fa-trash-alt icon m-0"></i>
        </Button>
      ),
    },
  ];


  const columnsFerragem = [
    { Header: "Espessura", accessor: "espessura" },
    { Header: "Cores", accessor: "cores"  },
    {
      Header: "Ações",
      accessor: "acao",
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <Button variant="danger" onClick={() => handleDelete(original)}>
          Excluir
        </Button>
      ),
    },
  ];
  return (
    <>
      <div className="row mb-3">
      </div>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="row">
        {
             kit?.tipoKit.value === 1 && (
              <>
                <div className="col-3">
                  <label>Largura</label>
                  <Input
                    name="largura"
                    type="number"
                    className="form-control"
                    placeholder="Ex.: 800"
                  />
                </div>
                <div className="col-3">
                  <label>Altura</label>
                  <Input
                    name="altura"
                    type="number"
                    className="form-control"
                    placeholder="Ex.: 1000"
                  />
                </div>
              </>
            )
          }
          

          <div className="col-3">
            <label>Espessura</label>
            <Input name="espessura" type="number" className="form-control" placeholder="Ex.: 6" />
          </div>
          <div className="col-3">
            <label>Cores</label>
            <Select
                isMulti
                options={coresData}
                value={selectedCores}
                onChange={handleCoresChange} 
                name={"cores"}          
              />
          </div>
        </div>
        <div className="mt-3">
          <Button variant="white" onClick={() => formRef.current?.submitForm()}>
            Adicionar
          </Button>
        </div>
      </Form>

      <div className={'dimmer' + (tableLoading ? ' active' : '')} style={tableLoading ? { minHeight: "322px" } : {}}>
        <div className="loader"></div>
        <div className="dimmer-content">
          {tableData && tableData.length > 0 ? (
            <DataTable columns={kit?.tipoKit.value === 2 ? columnsFerragem : columns} data={tableData} setSelectedRows={undefined} />
          ) : (
            <DataNotFound visible={!tableLoading} />
          )}
        </div>
      </div>
    </>
  );
};

export default KitTabMedidasCores;