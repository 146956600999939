import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import api from "../../../services/api";
import Componente from "./Componente";
import Perfis from "./Perfis";
import Superficie from "./Superficies";
import Vidros from "./Vidros";
import Kit from "../Produto/Kit";
import Kits from "./Kit";

const Item: React.FC = () => {
  // URL Params
  const { pId, pTipo }: any = useParams();

  // States
  const [dadosComponentes, setDadosComponentes] = useState(null);
  const [dadosPerfis, setDadosPerfis] = useState(null);
  const [dadosSuperficie, setDadosSuperficie] = useState(null);
  const [dadoskit, setDadosKit] = useState(null);
  const [dadosVidros, setDadosVidros] = useState(null);

  // Effects
  useEffect(() => {
    (async () => {
      try {        
          switch (pTipo.toUpperCase()) {
            case "COMPONENTE":
              const componente = await api.get(`componente/${pId}`);
              setDadosComponentes(componente.data);
              break;
            case "PERFIL":
              const perfil = await api.get(`perfil/${pId}`);
              setDadosPerfis(perfil.data);
              break;
            case "VIDRO":
              const vidro = await api.get(`vidro/${pId}`);              
              setDadosVidros(vidro.data);
              break;
            case "SUPERFICIE":              
            const superficie = await api.get(`superficie/${pId}`);
              setDadosSuperficie(superficie.data);  
              break;
            case "KIT":              
            const kit = await api.get(`kit/${pId}`);
            setDadosKit(kit.data);  
              break;
        }
      } catch (error) {}
    })(); 
  }, []);

  return (
    <>
    {!!dadosComponentes &&
      <Componente dados={dadosComponentes}/>
    }
    {!!dadosPerfis &&
      <Perfis dados={dadosPerfis} />
    }
    {!!dadosVidros &&
      <Vidros dados={dadosVidros} />
    }
    {!!dadosSuperficie &&
      <Superficie dados={dadosSuperficie} />
    }
    {!!dadoskit &&
      <Kits dados={dadoskit} />
    }
    </>
  );
};
export default Item;
