import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Button, Form as BootstrapForm, Table } from "react-bootstrap";
import { Input, Select } from "../../../../../components/Form";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import api from "../../../../../services/api";

import DataTable from "../../../../../components/DataTable";
import DataNotFound from "../../../../../components/DataNotFound";

import { ToastContext } from "../../../../../contexts/toast/toastContext";
import { SweetAlertContext } from "../../../../../contexts/sweetAlert/alertContext";
import { debounce } from "lodash";

const KitTabComposicao: React.FC<any> = ({ kit }) => {
  const { pId, pString }: any = useParams();
  const formRef = useRef<FormHandles>(null);

  const sweetAlertContext = useContext(SweetAlertContext);
  const toastContext = useContext(ToastContext);
  const [reload, setReload] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tipo, setTipo] = useState<"perfil" | "componente" | "livre">("perfil");
  const [options, setOptions] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<any | null>(null);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setTableLoading(true);
        if(kit?.id != undefined ){
          const endpointComposicao= `Kit/Composicao/${kit?.id}`;
          const { data: composicaoData } = await api.get(endpointComposicao);

          setTableData(composicaoData);
        }
       

        const endpoint = tipo === "perfil" ? "kit/perfis" : "kit/componentes";

        const { data: generalData } = await api.get(endpoint);
        setOptions(
          generalData.map((item: any) => ({
            value: item.value,
            label: item.label,
          }))
        );
      } catch (error) {
        console.error("Erro ao buscar opções:", error);
        toastContext.notification(
          "Erro ao carregar dados das opções.",
          "error"
        );
      } finally {
        setTableLoading(false);
      }
    };

    fetchOptions();
  }, [tipo, reload]);

  const handleSubmit: SubmitHandler<any> = async (item, { reset }) => {
    try {
      const kitusinagem = {
        codigoProduto: tipo === "livre" ?item.codigo :  item.codigo?.value,
        descricao: item.descricao,
        usinagem: item.usinagem,
        unidade : item.unidade,
        quantidade: Number(item.quantidade),
        idKit: kit?.id,
        Tipo: tipo
      };
        debugger
      if (item.usinagem && !item.usinagem.includes('=')){
        return toastContext.notification("O Campo Usinagem deve conter o sinal de = entre os termos.", "error");
      }

      const endpoint = "kit/SalvarComposicao";
      await api.post(endpoint, kitusinagem);

      setReload((reload) => !reload);
      reset();
      toastContext.notification(
        "Composição adicionada com sucesso!",
        "success"
      );
    } catch (error) {
      toastContext.notification("Erro ao salvar composição.", "error");
    }
  };

  const handleDelete = (item: any) => {
    console.log(item)
    sweetAlertContext.danger(
      "Excluir Composição?",
      "Essa ação não pode ser desfeita.",
      async () => {
        try {
          const endpoint = `kit/RemoverComposicao/${item.idComposicao}`;
          await api.delete(endpoint);
          setTableData((prevState: any) =>
            prevState.filter((i: any) => i.idComposicao !== item.idComposicao)
          );
          sweetAlertContext.close();
        } catch (error) {
          toastContext.notification("Erro ao excluir o item.", "error");
        }
      }
    );
  };

  const columns = [
    { Header: "Código", accessor: "codigoProduto" },
    { Header: "Descrição", accessor: "descricao" },
    { Header: "UN", accessor: "unidade" },
    { Header: "Quantidade", accessor: "quantidade" },
    { Header: "Def.Variáveis/Usinagem", accessor: "usinagem" },
    {
      Header: "Ações",
      accessor: "acao",
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <Button
          variant="ghost-dark"
          className="p-2"
          onClick={() => handleDelete(original)}
        >
          <i className="far fa-trash-alt icon m-0"></i>
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="row mb-3"></div>

      <BootstrapForm>
        <div className="d-flex">
          <BootstrapForm.Check
            type="radio"
            label="Perfil"
            name="tipoPerfil"
            value="perfil"
            checked={tipo === "perfil"}
            onChange={() => setTipo("perfil")}
            className="mr-3"
          />
          <BootstrapForm.Check
            type="radio"
            label="Componente"
            name="tipoComponente"
            value="componente"
            checked={tipo === "componente"}
            onChange={() => setTipo("componente")}
            className="mr-3"
          />
          <BootstrapForm.Check
            type="radio"
            label="Livre"
            name="tipolivre"
            value="livre"
            checked={tipo === "livre"}
            onChange={() => setTipo("livre")}
              className="mr-3"
          />
        </div>
      </BootstrapForm>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <div className="row">
          {/* Condicional para exibir campos específicos do tipo "livre" */}
          {tipo === "livre" ? (
            <>
               <div className="col-3">
                <label>Produto</label>
                <Input
                  name="codigo"
                  type="text"
                  className="form-control"
                  placeholder="Ex.: Código do item"
                />
              </div>
              <div className="col-3">
                <label>Descrição</label>
                <Input
                  name="descricao"
                  type="text"
                  className="form-control"
                  placeholder="Ex.: Descrição do item"
                />
              </div>
              <div className="col-1">
                <label>Unidade</label>
                <Input
                  name="unidade"
                  type="text"
                  className="form-control"
                  placeholder="Ex.: UN"
                />
              </div>
              <div className="col-1">
                <label>Quantidade</label>
                <Input
                  name="quantidade"
                  type="number"
                  className="form-control"
                  placeholder="Ex.: 2"
                />
              </div>
              <div className="col-3">
                <label>Usinagem</label>
                <Input
                  name="usinagem"
                  type="text"
                  className="form-control"
                  placeholder="Ex.: FEC=1010"
                />
              </div>
            </>
          ) : (
            /* Campos comuns para "perfil" e "componente" */
            <>
              <div className="col-3">
                <label>Produto</label>
                <Select
                  options={options}
                  onChange={(option) => setSelectedOption(option)}
                  name="codigo"
                />
              </div>
              <div className="col-3">
                <label>Quantidade</label>
                <Input
                  name="quantidade"
                  type="number"
                  className="form-control"
                  placeholder="Ex.: 2"
                />
              </div>
              <div className="col-3">
                <label>Usinagem</label>
                <Input
                  name="usinagem"
                  type="text"
                  className="form-control"
                  placeholder="Ex.: FEC=1010"
                />
              </div>
            </>
          )}
        </div>
        <div className="mt-3">
          <Button variant="white" onClick={() => formRef.current?.submitForm()}>
            Adicionar
          </Button>
        </div>
      </Form>


      <div
        className={"dimmer" + (tableLoading ? " active" : "")}
        style={tableLoading ? { minHeight: "322px" } : {}}
      >
        <div className="loader"></div>
        <div className="dimmer-content">
          {tableData && tableData.length > 0 ? (
            <DataTable
              columns={columns}
              data={tableData}
              setSelectedRows={undefined}
            />
          ) : (
            <DataNotFound visible={!tableLoading} />
          )}
        </div>
      </div>
    </>
  );
};

export default KitTabComposicao;
