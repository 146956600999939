import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Input, InputDecimal, Select } from "../../../../../components/Form";
import api from "../../../../../services/api";
import {
  situacao,
  status,
  tipoKit,
} from "../../../../../components/Form/Select/options";

import { ProdutoContext } from "../../../../../contexts/produtos/produtosContext";

const KitTabGeral: React.FC<any> = ({
  selectValues,
  setLogoLinha,
  loadingValues,
}) => {
  // URL Params
  const { pId }: any = useParams();

  // É um novo kit?
  const novo = pId == 0;

  // Contexts
  const produtoContext = useContext(ProdutoContext);

  //States
  const [linha, setLinha] = useState<any>([]);

  //Effects
  useEffect(() => {
    produtoContext.setEntrouEmEdicao(false);
  }, []);

  useEffect(() => {
    if (produtoContext.entrouEmEdicao &&Object.keys(selectValues).length != 0) {
      selectValues.formRef?.current?.setFieldValue("situacao", situacao[0]);
    }
  }, [produtoContext.entrouEmEdicao]);

  //Handles
  const handleReferenciaLinha = async ({ value }: any) => {
    selectValues.formRef?.current?.clearField("linha");

    const { data } = await api.get(`linha/select/${value}`);
    setLinha(data);

    handleChangeField();
  };

  const handleLinha = async (e: any) => {
    if (e != undefined) {
      const { data } = await api.get(`linha/logoLinha/${e.value}`);
      setLogoLinha(data);

      handleChangeField();
    }
  };

  const handleChangeField = () => {
     if (!produtoContext.entrouEmEdicao && !loadingValues) {
      produtoContext.setEntrouEmEdicao(true);
    }
  };  

  return (
    <>
      <div className={"dimmer" + (loadingValues ? " active" : "")}>
        <div className="loader"></div>
        <div
          className={
            "dimmer-content" + (loadingValues ? " medium-box-loader" : "")
          }
        >
          <div className="form-row mt-4">
            <div className="form-group col">
              <label className="form-label">Status *</label>
              <Select
                name="status"
                options={status}
                placeholder="Selecione uma opção..."
                defaultValue={novo ? status[0] : ""}
                isDisabled={novo}
                onChange={handleChangeField}
              />
            </div>
            <div className="form-group col">
              <label className="form-label">Situação *</label>
              <Select
                name="situacao"
                options={situacao}
                placeholder="Selecione uma opção..."
                defaultValue={novo ? situacao[0] : ""}                
              />
            </div>
            <Form.Group className="col">
              <Form.Label>Código *</Form.Label>
              <Input
                type="text"
                name="codigo"
                className="form-control"
                placeholder="Código..."
                onChange={handleChangeField}
              />
            </Form.Group>
            <Form.Group className="col">
              <Form.Label>Código Interno</Form.Label>
              <Input
                type="text"
                name="codigoInterno"
                className="form-control"
                placeholder="Código Interno..."
                onChange={handleChangeField}
              />
            </Form.Group>
          </div>

          <div className="form-row">
            <div className="form-group col">
              <label className="form-label">Referência *</label>
              <Select
                name="referencia"
                options={selectValues.referencia}
                onChange={(e) => {
                  handleReferenciaLinha(e);
                }}
                fixDelay
                placeholder="Selecione uma opção..."
              />
            </div>
            <div className="form-group col">
              <label className="form-label">Linha *</label>
              <Select
                name="linha"
                placeholder="Selecione uma opção..."
                options={linha}
                onChange={(e) => {
                  handleLinha(e);
                }}
              />
            </div>
          </div>
          <Form.Row>
            <Form.Group className="col">
              <Form.Label>Descrição *</Form.Label>
              <Input
                type="text"
                name="descricao"
                className="form-control"
                placeholder="Descrição..."
                onChange={handleChangeField}
              />
            </Form.Group>
          </Form.Row>
          <div className="form-row">
          <div className="form-group col">
              <label className="form-label">Tipo *</label>
              <Select
                name="tipoKit"
                options={tipoKit}
                placeholder="Selecione uma opção..."
                defaultValue={novo ? tipoKit[2] : ""}
                onChange={handleChangeField}
              />
            </div>
            <div className="form-group col">
              <label className="form-label">Grupo *</label>
              <Select
                name="grupo"
                options={selectValues.grupo}
                placeholder="Selecione uma opção..."
                onChange={handleChangeField}
              />
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};
export default KitTabGeral;
