import api from './api';
import { ISession, ILogin, ITrocaLogin } from '../common/interfaces/signIn.interfaces';
import { ApiResponse } from '../common/interfaces/api';
import axios from 'axios';

export function signIn(login: ILogin): Promise<ISession> {
    return api.post('/login/authenticate', login).then(res => res.data);
}

export function signInChange(trocaLogin: ITrocaLogin): Promise<ISession> {
    return api.post('/login/changeUser', trocaLogin).then(res => res.data);
}

export async function getTokenViaHashId(hashId: string) {
    try {
        const response: ApiResponse<string> = await axios.get(
        `${process.env.REACT_APP_API_NEW_SITE}Autenticacao/ObterTokenViaHashId?hashId=${hashId}`
        );
        
        return response.data;
    } catch (error: any) {
        console.error(
        error.data.errors.Mensagens[0] ||
        "Tivemos problemas internos ao obter JwtToken via hashId")
    }
}