import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, Fade } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import { EndMessage, Loading } from "../../components/InfiniteScroll";
import { SweetAlertContext } from "../../contexts/sweetAlert/alertContext";
import api from "../../services/api";
import history from "../../helpers/history";
import { ProdutoContext } from "../../contexts/produtos/produtosContext";
import FiltrosProdutos from "./Filtros";
import Itens from "./Itens";
import {
  IFiltro,
  IListProduto,
  IProdutoItem,
} from "../../common/interfaces/produtos.interfaces";
import { useAuth } from "../../hooks/auth";
import { toast } from "react-toastify";
import { getSingleErrorMessage } from "../../helpers/getSingleErrorMessage";
import DataNotFoundProducts from "../../components/DataNotFoundProducts";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const Produtos: React.FC = () => {
  // Fields
  const emEdicao = useRef(false);
  const queryString = require("querystring");
  const limite = 12;
  const { user } = useAuth();
  const { replace } = useHistory();

  // Contexts
  const sweetAlertContext = useContext(SweetAlertContext);
  const produtoContext = useContext(ProdutoContext);

  const filtro = produtoContext.filtro;
  // States
  const [modoEdicao, setModoEdicao] = useState(false);
  const [loading, setLoading] = useState(true);
  const [produto, setProduto] = useState<IListProduto>({} as IListProduto);
  const [referenciasLiberada, setReferenciasLiberada] = useState([]);
  const [homologar, setHomologar] = useState(false);
  const [
    existeProdutosNaoDisponivelMercado,
    setExisteProdutosNaoDisponivelMercado,
  ] = useState(false);
  const [disponivel, setDisponivel] = useState(false);
  const [qtdSearch, setQtdSearch] = useState(0);

  // Effects
  useEffect(() => {
    const fetchData = async () => {
      const modoEdicao = await api.get("produto/modoEdicaoValue");
      setModoEdicao(modoEdicao.data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      await api
        .get("geral/selecaoReferenciaMaterialLiberada")
        .then((response) => setReferenciasLiberada(response.data))
        .catch((error) => toast.error(getSingleErrorMessage(error)));

      await api
        .get("produto/acaoPermitidaMaterial")
        .then((response) => handleAcaoPermitida(response.data))
        .catch((error) => toast.error(getSingleErrorMessage(error)));

      await api
        .get("produto/ExisteProdutosNaoDisponivelMercado")
        .then((response) =>
          setExisteProdutosNaoDisponivelMercado(response.data)
        )
        .catch((error) => toast.error(getSingleErrorMessage(error)));

      setQtdSearch(0);
      fetchData(filtro, modoEdicao);
    };

    fetch();
  }, [modoEdicao]);

  if (referenciasLiberada && !havePermission("ConsultaProduto"))
    emEdicao.current = true;
  // Callbacks
  const fetchData = async (filtro: IFiltro, modoEdicao: boolean) => {
    replace(`/produtos?${queryString.stringify(filtro)}`);
    setQtdSearch(0);
    setLoading(true);
    var response = await api
      .get(
        `/produto/lista?limite=${limite}&pageSkip=${0}&modoEdicao=${modoEdicao}&${queryString.stringify(
          filtro
        )}`
      )
      .then((response) => {
        if (response.data.items) {
          setProduto(response.data);
          setQtdSearch((qtdSearch) => qtdSearch + 1);
        } else {
          setProduto(response.data);
        }
      })
      .catch((error) => {
        toast.error(getSingleErrorMessage(error));
      })
      .finally(() => {
        setLoading(false);
      });

    return response;
  };

  const fetchMoreData = async () => {
    await api
      .get(
        `/produto/lista?limite=${limite}&pageSkip=${qtdSearch}&modoEdicao=${modoEdicao}&${queryString.stringify(
          filtro
        )}`
      )
      .then((response) => {
        if (response.data.items) {
          produto.items = produto.items.concat(response.data.items);
          setProduto(produto);
          setQtdSearch((qtdSearch) => qtdSearch + 1);
        } else {
          setProduto((produto) => {
            return { ...produto, hasMore: false };
          });
        }
      })
      .catch((error) => toast.error(getSingleErrorMessage(error)));
  };

  //Handles
  const handleAcaoPermitida = ({ homologar, disponibilizar }: any) => {
    setHomologar(homologar);
    setDisponivel(disponibilizar);
  };

  const handleNew = () => {
    history.push(`/produtos/0/novoProduto`);
  };

  const handleModoEdicao = async (modoEdicao: boolean) => {
    try {
      await api.post(`produto/modoEdicao/${modoEdicao}`);
      setModoEdicao(modoEdicao);
    } catch (err) {
      toast.error(getSingleErrorMessage(err));
    }
  };

  const handleHomologar = async () => {
    const title = "Homologar";
    const message =
      "Deseja homologar as alterações realizadas, isso não pode ser desfeito.";

    sweetAlertContext.warning(title, message, async () => {
      try {
        var promise = new Promise(async (resolve, reject) => {
          try {
            const {
              data: { total },
            } = await api.post("produto/homologar");
            resolve(response);
          } catch (error) {
            reject(error);
            toast.error(getSingleErrorMessage(error));
          }
        });

        var response = await toast
          .promise(promise, {
            pending: "Homologando materiais...",
            error: "Não há materiais em edição para homologação",
            success:
              "A ação foi concluída. Em breve será disponibilzado o pacote de atualização para importação no seu sistema para homologação.",
          })
          .then(async (data) => {
            const acaoPermitida = await api.get(
              "produto/acaoPermitidaMaterial"
            );
            handleAcaoPermitida(acaoPermitida.data);
            fetchData(filtro, modoEdicao);
          });
      } catch (err) {
        toast.error(getSingleErrorMessage(err));
      }
    });
  };

  const handleDisponibilizar = async () => {
    const title = "Disponível para Mercado";
    const message =
      "Deseja tornar as atualizações disponíveis para uso dos clientes?";
  
    const { value: selectedOptions } = await Swal.fire({
      title: title,
      html: `
        <input type="checkbox" id="cemMobi" name="cemMobi">
        <label for="cemMobi"> CEM MOBI</label><br>
        <input type="checkbox" id="cemDelphi" name="cemDelphi">
        <label for="cemDelphi"> CEM DELPHI</label>
      `,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      focusConfirm: false,
      didOpen: () => {
        const cemMobiCheckbox = document.getElementById(
          "cemMobi"
        ) as HTMLInputElement;
        const cemDelphiCheckbox = document.getElementById(
          "cemDelphi"
        ) as HTMLInputElement;
        if (existeProdutosNaoDisponivelMercado) {
          cemDelphiCheckbox.checked = true;
          cemDelphiCheckbox.disabled = true; 
        }
  
       
        cemMobiCheckbox.addEventListener("change", () => {
          if (cemMobiCheckbox.checked) {
            cemDelphiCheckbox.checked = true;
            cemDelphiCheckbox.disabled = true; 
          }
        });
      },
      preConfirm: () => {
        const cemMobi = (document.querySelector("#cemMobi") as HTMLInputElement)
          .checked;
        const cemDelphi = (
          document.querySelector("#cemDelphi") as HTMLInputElement
        ).checked;
  
        if (!cemMobi && !cemDelphi) {
          return Swal.showValidationMessage(
            "Você precisa selecionar ao menos uma opção!"
          );
        }
        return { cemMobi, cemDelphi };
      },
    });
  
    if (selectedOptions) {
      try {
        let optionToSend: string;
  
        if (selectedOptions.cemMobi && selectedOptions.cemDelphi) {
          optionToSend = "todos";
        } else if (selectedOptions.cemMobi) {
          optionToSend = "CEM_MOBI";
        } else if (selectedOptions.cemDelphi) {
          optionToSend = "CEM_PRO";
        }
  
        const promise = new Promise(async (resolve, reject) => {
          api
            .post(`produto/disponibilizar/${optionToSend}`)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.response.data);
            });
        });
  
        await toast
          .promise(promise, {
            pending: "Disponibilizando materiais...",
            error: {
              render({ data }: any) {
                return data;
              },
            },
            success:
              "A ação foi concluída. Em breve estará disponível para importação nos sistemas dos clientes.",
          })
          .then(async (data) => {
            const acaoPermitida = await api.get("produto/acaoPermitidaMaterial");
            handleAcaoPermitida(acaoPermitida.data);
            fetchData(filtro, modoEdicao);
          });
      } catch (err) {
        toast.error(getSingleErrorMessage(err));
      }
    }
  };
  

  const handleProdutoEditavel = (produto: IProdutoItem) => {
    const referenciaLiberada = referenciasLiberada.some(
      (referencia: any) =>
        referencia.value == produto.idReferencia &&
        produto.tipoProduto == referencia.label &&
        !produto.aplicabilidadeBaseEditavel
    );

    return (
      referenciaLiberada && modoEdicao && !havePermission("ConsultaProduto")
    );
  };

  function havePermission(permission: string): boolean {
    var isValid = user?.role.indexOf(permission) !== -1;
    return isValid;
  }

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header">
            {!havePermission("ConsultaProduto") && (
              <div className="row">
                <div className="col-3">
                  <h2 className="page-title">Produtos</h2>
                </div>
                <div className="col-auto">
                  <div className="btn-list mr-2">
                    <Fade in={modoEdicao}>
                      <div>
                        {modoEdicao && (
                          <>
                            <Button
                              variant="white"
                              className="mr-2"
                              onClick={handleNew}
                            >
                              Novo Produto
                            </Button>
                            <Button
                              variant="white"
                              className="mr-2"
                              onClick={handleHomologar}
                              disabled={!homologar}
                            >
                              Homologar
                            </Button>
                            {havePermission("Master") && (
                              <Button
                                variant="white"
                                onClick={handleDisponibilizar}
                                disabled={!disponivel}
                              >
                                Disponibilizar
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </Fade>
                  </div>
                </div>
                {emEdicao.current && (
                  <div className="mt-2 pr-0 col-auto ml-auto">
                    <label className="custom-switch" htmlFor="edicao">
                      <input
                        type="checkbox"
                        id="edicao"
                        checked={modoEdicao}
                        className="custom-switch-input"
                        onChange={(e) => handleModoEdicao(e.target.checked)}
                      />
                      <span className="custom-switch-indicator"></span>
                      <span className="custom-switch-description">
                        Modo Edição
                      </span>
                    </label>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="row">
            {/*Filtros*/}
            <div className="col-3">
              <FiltrosProdutos emEdicao={modoEdicao} fetchData={fetchData} />
              <button
                className="btn"
                style={{
                  backgroundColor: "#2C3955",
                  color: "white",
                  width: "100%",
                }}
                onClick={async () => {
                  setQtdSearch(0);
                  await fetchData(filtro, modoEdicao);
                }}
              >
                Buscar
              </button>
            </div>

            {/*Itens*/}
            <div className="col-9 pr-0">
              <div className={"dimmer" + (loading ? " active" : "")}>
                <div className="loader"></div>
                <div
                  className={
                    "dimmer-content" + (loading ? " medium-box-loader" : "")
                  }
                >
                  {produto.total > 0 && (
                    <InfiniteScroll
                      dataLength={produto.items.length}
                      next={() => fetchMoreData()}
                      hasMore={produto.hasMore}
                      loader={<Loading />}
                      className="row"
                      endMessage={<EndMessage />}
                      scrollThreshold={produto.threshold}
                    >
                      {produto.items.map(
                        (objProduto: IProdutoItem, index: number) => (
                          <div
                            className={
                              "col-4 item" +
                              (objProduto?.status == "Descontinuado" ||
                              objProduto.status == "Arquivo Morto"
                                ? " inativo"
                                : "")
                            }
                            key={index}
                          >
                            <Itens
                              produto={objProduto}
                              podeEditar={handleProdutoEditavel(objProduto)}
                              modoEdicao={emEdicao.current}
                              aplicabilidadeEdicao={
                                modoEdicao &&
                                objProduto.aplicabilidadeBaseEditavel
                              }
                            />
                          </div>
                        )
                      )}
                    </InfiniteScroll>
                  )}
                </div>
              </div>
              {produto.total === 0 && !loading && (
                <div
                  className="card d-flex justify-content-center"
                  style={{ minHeight: "60vh" }}
                >
                  <DataNotFoundProducts
                    visible={produto.items?.length > 0 && qtdSearch >= 0}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Produtos;
