import React, { useEffect, useState, useMemo, useRef } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import api from "../../../../services/api";
import { base64toBlob } from "../../../../helpers/utils";
import DataTable from "../../../../components/DataTable";
import DataNotFound from "../../../../components/DataNotFound";
import {
  IOutrasCaracteristicas,
  IAplicabilidade,
} from "../../../../common/interfaces/produtos.interfaces";
import Placeholder from "../../../../assets/imgs/img-placeholder.png";
import { useAuth } from "../../../../hooks/auth";
import ModalPrevisualizacao from "../../AplicabilidadeBase/ModalPrevisualizacao";
import { toast } from "react-toastify";
import { getSingleErrorMessage } from "../../../../helpers/getSingleErrorMessage";
import BackButton from "../../../../components/ButtonBack";

interface IKit {
  dados: any;
}

const Kits: React.FC<IKit> = (props) => {
  // URL Params
  const { pId }: any = useParams();

  // States
  const [mostrarModalVerMais, setMostrarModalVerMais] = useState(false);
  const emValidacao = useRef("");
  const [outrasCaracteristica, setOutrasCaracteristica] = useState<
    IOutrasCaracteristicas[]
  >([]);
  const [logoPreview, setLogoPreview] = useState([]);
  const [showAplicavel, setShowAplicavel] = useState(false);
  const [idAplicavel, setIdAplicavel] = useState<any>(null);
  const [tableData, setTableData] = useState<IAplicabilidade[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modoEdicao, setModoEdicao] = useState(false);
  const modalVerMais = () => setMostrarModalVerMais(true);

  const handleClose = () => [
    setMostrarModalVerMais(false)
  ];
  function havePermission(permission: string): boolean {
    var isValid = user?.role.indexOf(permission) !== -1;
    return isValid;
  }
  const baseUrlImagem =
    process.env.REACT_APP_API + "figurasicones/kits/figuras/";

  //Fields Typed
  const DataTableTS: any = DataTable;
  const { user } = useAuth();

  //Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        emValidacao.current =
          props.dados?.situacao?.label == "Em Edição" ||
          props.dados?.situacao?.label == "Em Homologação"
            ? "validacao/"
            : "";
      
        setTableData([]);
        setOutrasCaracteristica([]);
        setLogoPreview([]);

        if (user?.role.indexOf("ConsultaProduto") === -1) {
          const modoEdicao = await api.get("produto/modoEdicaoValue");
          setModoEdicao(modoEdicao.data);
        }
      } catch (error) {
        toast.error(getSingleErrorMessage(error));
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Código",
        accessor: "codigo",
        display: "align-middle",
      },
      {
        Header: "Descrição",
        accessor: "descricao",
        display: "align-middle",
      },
      {
        Header: "Referência",
        accessor: "referencia",
        display: "align-middle",
        Cell: ({
          row: { original },
        }: {
          row: { original: IAplicabilidade };
        }) => {
          return (
            original.linkLogoReferencia && (
              <Image
                height={22}
                src={original.linkLogoReferencia}
                onError={(event: any) => (event.target.src = Placeholder)}
              />
            )
          );
        },
      },
      {
        Header: "Situação",
        accessor: "situacao",
        display: "align-middle",
      },
      {
        Header: "Ação",
        accessor: "acao",
        display: "align-middle column-table-2",
        Cell: ({
          row: { original },
        }: {
          row: { original: IAplicabilidade };
        }) => (
          <span className="d-none d-sm-inline">
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open(original.fichaTecnica, "_blank");
              }}
              title="Ficha Técnica"
              variant="ghost-dark"
              className="p-2"
            >
              <i className="fas fa-file-download icon m-0"></i>
            </Button>
            <Button
              title="Visualizar"
              variant="ghost-dark"
              className="p-2"
              target="_blank"
              onClick={() =>(original)}
            >
              <i className="fas fa-external-link-alt icon m-0"></i>
            </Button>
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="content">
        <div className="container-xl">
          <div className="page-header d-print-none">
            <div className="row align-items-center">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-sm-5 pl-0">
                      <div className="card card-sm">
                        <Image
                          src={
                            props.dados?.figura
                              ? baseUrlImagem +
                                emValidacao.current +
                                props.dados?.figura
                              : Placeholder
                          }
                          onError={(event: any) =>
                            (event.target.src = Placeholder)
                          }
                          className="card-img-top"
                        />
                      </div>
                    </div>
                    <div className="col-sm-7 pl-4 pr-0">
                      <div className="card-body pb-0">
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="mt-3 mb-3">
                              <Image
                                src={
                                  props.dados?.linkLogoReferencia != null
                                    ? props.dados.linkLogoReferencia
                                    : Placeholder
                                }
                                onError={(event: any) =>
                                  (event.target.src = Placeholder)
                                }
                                height="30px"
                                className="font-weight-bold"
                              />
                            </div>
                            <div className="col-sm-12 my-3 p-0">
                              {props.dados?.linkDesenhoTecnico && (
                                <Button
                                  className="btn btn-mof btn-sm mr-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(
                                      props.dados?.linkDesenhoTecnico,
                                      "_blank"
                                    );
                                  }}
                                >
                                  Ficha Técnica
                                  <i className="far fa-file-pdf pl-3"></i>
                                </Button>
                              )}
                            </div>
                            <div className="font-weight-bold">
                              {props.dados?.codigo}
                            </div>
                            <div>{props.dados?.descricao}</div>
                            <div className="row text-muted">
                              <div className="col-sm-12">
                                <div className="row">
                                  <div className="mt-3 col-sm-12">
                                    {modoEdicao && (
                                      <span className="badge badge-xl bg-green-lt text-uppercase h4">
                                        {props.dados?.situacao?.label}
                                      </span>
                                    )}
                                    <span
                                      className={`${
                                        modoEdicao ? "ml-1" : ""
                                      } badge badge-xl bg-azure-lt text-uppercase h4`}
                                    >
                                      {props.dados?.status?.label}
                                    </span>
                                  </div>
                                  <div className="mt-3 col-sm-12">
                                    <Button
                                      onClick={modalVerMais}
                                      variant="white"
                                      className="mr-2"
                                    >
                                      Ver mais
                                    </Button>
                                    {
                                        !havePermission("ConsultaProduto") && modoEdicao && (
                                        <>
                                          <Link
                                            to={`../../../../produtos/${props.dados.id}/${encodeURIComponent(props.dados.codigo.replace('/', ''))}/kit`}
                                            className="btn btn-white"
                                            title="Editar"
                                          >
                                            <i className="far fa-edit icon m-0"></i>
                                          </Link>
                                        </>
                                      )
                                    }
                                  </div>
                                  <div className="mt-3 col-sm-12">
                                    <BackButton />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {outrasCaracteristica.length != 0 && (
                    <div className="card row mt-3">
                      <div className="card-header">
                        <h3 className="card-title">OUTRAS CARACTERÍSTICAS:</h3>
                      </div>
                      <div className="card-body">
                        <div className="row text-muted">
                          <div className="col-sm-12">
                            <div className="row">
                              {outrasCaracteristica.map(
                                ({ nome, descricao }, index) => (
                                  <div className="col-sm-6" key={index}>
                                    <span className="font-weight-bold">
                                      {nome}:
                                    </span>{" "}
                                    {descricao}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Ver Mais*/}
      <Modal size="lg" show={mostrarModalVerMais} onHide={handleClose}>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Ver Mais</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-vcenter card-table ver-mais">
            <tr>
              <td className="border-0 font-weight-bold min-30">Código: </td>
              <td className="border-0">{props.dados?.codigo}</td>
            </tr>
            <tr>
              <td className="font-weight-bold min-30">Descrição: </td>
              <td className="text-muted">{props.dados?.descricao}</td>
            </tr>
            {props.dados?.codigoInterno && (
              <tr>
                <td className="font-weight-bold min-30">Código Interno: </td>
                <td className="text-muted">{props.dados?.codigoInterno}</td>
              </tr>
            )}
            <tr>
              <td className="font-weight-bold">Referência: </td>
              <td className="text-muted">{props.dados?.referencia?.label}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Linha: </td>
              <td className="text-muted">{props.dados?.linha?.label}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Grupo: </td>
              <td className="text-muted">{props.dados?.grupo?.label}</td>
            </tr>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

   </>
  );
};

export default Kits;
